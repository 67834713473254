.swal-wide {
  width: 80% !important;
}

.swal2-container .swal2-popup {
  width: 80% !important;
  max-width: 80% !important;
}

.thumbnail-container {
  position: relative;
}

.thumbnail {
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.thumbnail:hover {
  transform: scale(1.1);
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
