<template>
  <Breadcrumbs main="Troubleshooting" title="Order Details"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-10">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Search Date Range:
                  </div>
                  <div class="col">
                    <input v-model="fromTimestamp" type="date" class="form-control"
                           placeholder="From Timestamp">
                  </div>
                  <div class="col-auto text-center"> <!-- Center the icon in a narrower column -->
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                  <div class="col">
                    <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 END -->

              <!-- INPUT GROUP 2 START -->
              <div class="input-group m-0 col-sm-12">
                <div class="row align-items-center m-r-30">

                  <div class="col text-center f-w-700">
                    Order uuid:
                  </div>
                </div>

                <input v-model="orderUuid" class="form-control form-control-plaintext ryd-search-input"
                       type="search" id="order-details-search"
                       placeholder="Enter an order uuid from staging or production environment ..">

                <button @click="updateTabs"
                        class="btn btn-pill btn-success btn-air-success active input-group-text m-r-20">
                  <i class="fa fa-search m-r-5"></i>Search
                </button>

                <button @click="copyShareableLink" class="btn btn-info"
                        data-bs-toggle="popover"
                        data-bs-placement="left"
                        data-bs-title="Information"
                        data-bs-content="Click this button to generate a full URL, including your input fields (order UUID and search date range), making it easy to share with others.">
                  <i class="fa fa-share"></i> Share This Search
                </button>

              </div>
            </div>
            <!-- INPUT GROUP 2 END -->
          </div>
        </div>
        <!-- CARD 1 END -->


        <!-- CARD 2 START -->
        <div class="card">
          <div class="card-body">

            <div id="alertDiv1" v-if="showAlertOrderUuid" class="alert alert-secondary outline" role="alert">
              <p>Please enter a valid order uuid with at least 24 characters. </p>
            </div>
            <div id="alertDiv2" v-if="showAlertTimestamps" class="alert alert-secondary outline" role="alert">
              <p>Please Enter a Valid Search Date Range: Both 'From' and 'To' dates are required and cannot be left
                empty. Additionally, the time span between the 'From' and 'To' dates must not exceed 6 months. </p>
            </div>

            <div class="text-center" id="order-details-search-result-tabs">
              <ul class="nav nav-tabs search-list" id="top-tab" role="tablist">
                <li class="nav-item" v-on:click="changetab('apiGatewayHttpRequests')">
                  <a class="nav-link " :class="{'show active': showtab === 'apiGatewayHttpRequests' }" id="top-all"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays requests made from a partner or client to ryd's API Gateway (tt4.thinxcloud.de), along with response status codes and request times."
                     role="tab" aria-controls="apiGatewayHttpRequests" aria-selected="false"><i class="icon-target"></i>1.
                    API Gateway HTTP Requests</a>
                </li>
                <li class="nav-item" v-on:click="changetab('orderStateHistory')">
                  <a class="nav-link" :class="{'show active': showtab === 'orderStateHistory' }" id="top-images"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Provides a chronological record of the state history for the specified Order UUID."
                     role="tab" aria-controls="orderStateHistory" aria-selected="false"><i class="fa fa-history"></i>2.
                    Order State History </a>
                </li>
                <li class="nav-item" v-on:click="changetab('popEvents')">
                  <a class="nav-link" :class="{'show active': showtab === 'popEvents' }" id="top-videos"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays log messages generated during interactions between ryd and the gas station's cashier system (POP, Point Of Purchase)."
                     role="tab" aria-controls="popEvents" aria-selected="false"><i
                      class="fa fa-exchange"></i>3. Pop Events</a>
                </li>
                <li class="nav-item" v-on:click="changetab('errorLogMessages')">
                  <a class="nav-link" :class="{'show active': showtab === 'errorLogMessages' }" id="audios-link"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays a list of log messages categorized as Exception or Error."
                     role="tab" aria-controls="errorLogMessages" aria-selected="false"><i class="icon-close"> </i>4.
                    Error Log Messages</a>
                </li>
                <li class="nav-item" v-on:click="changetab('userAgents')" v-if="loggedInUserIsRyd">
                  <a class="nav-link" :class="{'show active': showtab === 'userAgents' }" id="audios-link"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays a list of all the user agents utilized during the processing of the specified Order UUID."
                     role="tab" aria-controls="userAgents" aria-selected="false"><i class="icon-direction"> </i>5. User Agents</a>
                </li>
                <li class="nav-item" v-on:click="changetab('evCharges')" v-if="loggedInUserIsRyd">
                  <a class="nav-link" :class="{'show active': showtab === 'evCharges' }" id="audios-link"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays the important information about an ev charge session."
                     role="tab" aria-controls="evCharges" aria-selected="false"><i class="icon-direction"> </i>6. EV Charge Info</a>
                </li>
              </ul>
            </div>

            <div class="tab-content" id="top-tabContent">

              <TabApiGatewayHttpRequests :api-response-api-gateway-http-requests="apiResponseApiGatewayHttpRequests"
                                         :showtab="showtab"
                                         :isLoadingApiGatewayHttpRequests="isLoadingApiGatewayHttpRequests"
                                         :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabApiStateEvents :api-response-api-order-state-events="apiResponseApiOrderStateEvents"
                                 :showtab="showtab"
                                 :isLoadingApiOrderStateEvents="isLoadingApiOrderStateEvents"
                                 :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabPopEvents :api-response-pop-events="apiResponsePopEvents"
                            :showtab="showtab"
                            :isLoadingPopEvents="isLoadingPopEvents" :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabErrorLogMessages :api-response-error-log-messages="apiResponseErrorLogMessages"
                                   :showtab="showtab"
                                   :isLoadingErrorLogMessages="isLoadingErrorLogMessages"
                                   :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabUserAgents :apiResponseUserAgents="apiResponseUserAgents"
                             :showtab="showtab"
                             :isLoadingUserAgents="isLoadingUserAgents"
                             :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabEvCharges :apiResponseEvSessions="apiResponseEvSessions"
                            :apiResponseEvSessionsEvents="apiResponseEvSessionsEvents"
                            :apiResponseEvSessionsCdr="apiResponseEvSessionsCdr"
                             :showtab="showtab"
                             :isLoadingEvSessions="isLoadingEvSessions"
                            :isLoadingEvSessionsEvents="isLoadingEvSessionsEvents"
                            :isLoadingEvSessionsCdr="isLoadingEvSessionsCdr"
                             :loggedInUserIsRyd="loggedInUserIsRyd"/>

            </div>
          </div>
        </div>
        <!-- CARD 2 END -->

        <!-- Order Cards Section -->
        <div class="cards-section mt-4">
          <!-- Information Alerts -->
          <div class="alert alert-info outline mb-4" role="alert">
            <i class="fas fa-info-circle"></i> <span class="mb-0 fw-bold" >The following sections display the 30 most recent orders from the last 90 days, sorted by creation date (newest first). Orders from both Production and Staging environments are shown separately. </span>
            <br/><br/>
            <i class="fas fa-info-circle"></i><span class="mb-0 fw-bold">Each Order UUID is clickable - clicking on an Order UUID will automatically populate it in the search field above and trigger a detailed search for that order.</span>
          </div>


          <!-- Partner Selection (for Ryd users only) -->
          <div class="row mb-4" v-if="loggedInUserIsRyd">
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label class="form-label fw-bold" for="partnerSelection">
                      Select Partner:
                      <small class="text-muted ms-2">Choose the partner whose orders you want to view</small>
                    </label>
                    <select
                        class="form-select border border-success"
                        id="partnerSelection"
                        v-model="selectedPartner"
                        @change="fetchOrders"
                    >
                      <option value="">Select a partner...</option>
                      <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                        {{ partnerName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Production Orders Card -->
          <div class="card mb-4">
            <div class="card-header bg-success p-3">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="text-white mb-0">Production Environment Orders</h5>
                <span class="badge bg-light text-primary" v-if="productionOrders.data">
          {{ productionOrders.data.length }} Orders Found
        </span>
              </div>
              <small class="text-white opacity-75">Showing the 30 most recent orders from the past 90 days</small>
            </div>

            <div class="card-body">
              <!-- Loading State -->
              <div v-if="isLoadingProdOrders" class="text-center py-4">
                <div class="loader-box">
                  <div class="loader-17"></div>
                </div>
              </div>

              <!-- Error State -->
              <div v-else-if="productionOrdersError" class="alert alert-danger" role="alert">
                <i class="fa fa-exclamation-triangle me-2"></i>
                {{ productionOrdersError }}
                <br/>
                <small class="text-muted">Mostly due to missing production environment account or configuration for you.</small>
              </div>

              <!-- No Data State -->
              <div v-else-if="!productionOrders.data || productionOrders.data.length === 0"
                   class="alert alert-warning" role="alert">
                <i class="fa fa-info-circle me-2"></i>
                No production orders found for the selected criteria.
              </div>

              <!-- Data Table -->
              <div v-else class="table-responsive">
                <table class="table table-hover border table-striped">
                  <thead class="bg-light">
                  <tr>
                    <th>Order UUID</th>
                    <th>Created At</th>
                    <th>Station Brand</th>
                    <th>Station Address</th>
                    <th>Station Country</th>
                    <th>Station Id (POI id)</th>
                    <th>Station Location</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="order in productionOrders.data" :key="order.orderUuid">
                    <td>
                      <a href="#"
                         @click.prevent="selectOrderAndSearch(order.orderUuid)"
                         class="text-primary text-decoration-none">
                        {{ order.orderUuid }}
                      </a>
                    </td>
                    <td>{{ order.createdAt }}</td>
                    <td>{{ order.poi?.brand || 'N/A' }}</td>
                    <td>{{ formatAddress(order.poi?.address) || 'Address not available' }}</td>
                    <td>{{ order.poi?.address?.countryCode || 'N/A' }}</td>
                    <td>{{ order.poi?.poiId || 'N/A' }}</td>
                    <td>
                      <a v-if="hasValidLocation(order.poi?.location)"
                         :href="getGoogleMapsLink(order.poi.location)"
                         target="_blank"
                         class="btn btn-sm btn-outline-primary">
                        <i class="fa fa-map-marker me-1"></i>View Map
                      </a>
                      <span v-else class="text-muted">Location not available</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Staging Orders Card -->
          <div class="card">
            <div class="card-header bg-primary p-3">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="text-white mb-0">Staging Environment Orders</h5>
                <span class="badge bg-light text-secondary" v-if="stagingOrders.data">
          {{ stagingOrders.data.length }} Orders Found
        </span>
              </div>
              <small class="text-white opacity-75">Showing the 30 most recent orders from the past 90 days</small>
            </div>

            <div class="card-body">
              <!-- Loading State -->
              <div v-if="isLoadingStagingOrders" class="text-center py-4">
                <div class="loader-box">
                  <div class="loader-18"></div>
                </div>
              </div>

              <!-- Error State -->
              <div v-else-if="stagingOrdersError" class="alert alert-danger" role="alert">
                <i class="fa fa-exclamation-triangle me-2"></i>
                {{ stagingOrdersError }}
                <br/>
                <small class="text-muted">Mostly due to missing staging environment account or configuration for you.</small>
              </div>

              <!-- No Data State -->
              <div v-else-if="!stagingOrders.data || stagingOrders.data.length === 0"
                   class="alert alert-warning" role="alert">
                <i class="fa fa-info-circle me-2"></i>
                No staging orders found for the selected criteria.
              </div>

              <!-- Data Table -->
              <div v-else class="table-responsive">
                <table class="table table-hover border table-striped">
                  <thead class="bg-light">
                  <tr>
                    <th>Order UUID</th>
                    <th>Created At</th>
                    <th>Station Brand</th>
                    <th>Station Address</th>
                    <th>Station Country</th>
                    <th>Station Id (POI id)</th>
                    <th>Station Location</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="order in stagingOrders.data" :key="order.orderUuid">
                    <td>
                      <a href="#"
                         @click.prevent="selectOrderAndSearch(order.orderUuid)"
                         class="text-primary text-decoration-none">
                        {{ order.orderUuid }}
                      </a>
                    </td>
                    <td>{{ order.createdAt }}</td>
                    <td>{{ order.poi?.brand || 'N/A' }}</td>
                    <td>{{ formatAddress(order.poi?.address) || 'Address not available' }}</td>
                    <td>{{ order.poi?.address?.countryCode || 'N/A' }}</td>
                    <td>{{ order.poi?.poiId || 'N/A' }}</td>
                    <td>
                      <a v-if="hasValidLocation(order.poi?.location)"
                         :href="getGoogleMapsLink(order.poi.location)"
                         target="_blank"
                         class="btn btn-sm btn-outline-primary">
                        <i class="fa fa-map-marker me-1"></i>View Map
                      </a>
                      <span v-else class="text-muted">Location not available</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- card 3 start-->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-success">
            <h5 class="text-white">Important Notes</h5>
          </div>

          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">1. Please enter a valid order UUID.</li>
              <li class="list-group-item">2. The search feature on this page is designed to function with order UUIDs
                from both <b>staging and production</b> environments.
              </li>
              <li class="list-group-item">3. Ensure you specify a valid search date range where
                the interval between the 'From' and 'To' dates <b>does not exceed 6 months</b>, to maintain optimal
                search
                performance.
              </li>
            </ul>
          </div>
        </div>
        <!-- card 3 end-->

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TabApiGatewayHttpRequests from "@/pages/troubleshooting/orderdetails/TabApiGatewayHttpRequests.vue";
import TabApiStateEvents from "@/pages/troubleshooting/orderdetails/TabApiStateEvents.vue";
import TabPopEvents from "@/pages/troubleshooting/orderdetails/TabPopEvents.vue";
import TabErrorLogMessages from "@/pages/troubleshooting/orderdetails/TabErrorLogMessages.vue";
import TabUserAgents from "@/pages/troubleshooting/orderdetails/TabUserAgents.vue";
import TabEvCharges from "@/pages/troubleshooting/orderdetails/TabEvCharges.vue";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import {Popover} from 'bootstrap/dist/js/bootstrap.esm.min';
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";


export default {
  keywords: ['Monitoring', 'Orders', 'Order Insights', 'User Agent', 'POP', 'Order State History', 'EV','POP Events', 'Charging', "Events"],
  components: {
    TabErrorLogMessages,
    TabPopEvents,
    TabApiStateEvents,
    TabApiGatewayHttpRequests,
    TabUserAgents,
    TabEvCharges
  },
  data() {
    return {
      showtab: 'apiGatewayHttpRequests',
      orderUuid: '',
      apiResponseApiGatewayHttpRequests: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseApiOrderStateEvents: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponsePopEvents: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseErrorLogMessages: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseUserAgents: {
        totalNumberOfResults: 0,
        userAgents: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseEvSessions: {
        totalNumberOfResults: 0,
        createdSessionId: '',
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseEvSessionsEvents: {
        totalNumberOfResults: 0,
        extractedData: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseEvSessionsCdr: {
        totalNumberOfResults: 0,
        extractedData: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      showAlertOrderUuid: false,
      showAlertTimestamps: false,
      showShareThisSearch: false,
      fromTimestamp: '',
      toTimestamp: '',
      isLoadingApiGatewayHttpRequests: false,
      isLoadingApiOrderStateEvents: false,
      isLoadingPopEvents: false,
      isLoadingErrorLogMessages: false,
      isLoadingUserAgents: false,
      isLoadingEvSessions: false,
      isLoadingEvSessionsEvents: false,
      isLoadingEvSessionsCdr: false,
      foundEvSessionId: '',
      selectedPartner: '',
      productionOrders: [],
      stagingOrders: [],
      isLoadingProdOrders: false,
      isLoadingStagingOrders: false,
      loggedInUserIsRyd: false,
      loggedInUserId: '',
      partnerNames: [],
      productionOrdersError: null,
      stagingOrdersError: null,
    }
  },
  mounted() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl =>
        new Popover(popoverTriggerEl, {
          trigger: 'focus hover'
        })
    );

    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;

    if (isUserGroupRyd) {
      this.loadPartnerNames(this.loggedInUserId);
    }

  },
  created() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;

    // First initialize parameters from URL
    this.initializeParameters();

    // Only set default if not set from URL
    if (!this.selectedPartner) {
      this.selectedPartner = isUserGroupRyd ? 'ryd' : userGroup;
    }

    if (this.selectedPartner) {
      this.loadPartnerNames(this.loggedInUserId);
    }

    this.checkAndTriggerSearch();
    this.fetchOrders();
  },
  watch: {
    selectedPartner(newVal) {
      if (newVal) {
        this.fetchOrders();
      }
    }
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    async updateTabs() {

      this.apiResponseApiGatewayHttpRequests = {};
      this.apiResponseApiOrderStateEvents = {};
      this.apiResponsePopEvents = {};
      this.apiResponseErrorLogMessages = {};
      this.apiResponseUserAgents = {};

      // Check for valid Order UUID
      if (!this.orderUuid || this.orderUuid.trim().length < 23) {
        this.showAlertOrderUuid = true;
        this.showAlertTimestamps = false;
        return; // Early return if orderUuid is invalid
      }

      // Check for valid timestamps
      if (!this.validateTimestamps()) {
        this.showAlertOrderUuid = false;
        this.showAlertTimestamps = true;
        return; // Early return if timestamps are invalid
      }

      this.showtab = 'apiGatewayHttpRequests';
      this.showAlertOrderUuid = false;
      this.showAlertTimestamps = false;

      this.isLoadingEvSessions = true;
      this.isLoadingEvSessionsEvents = true;
      this.isLoadingEvSessionsCdr = true;

      const orderUuidTrimmed = this.orderUuid.trim();
      try {
        await Promise.all([
          this.fetchApiGatewayHttpRequests(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchOrderStateEvents(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchPopEvents(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchErrorLogMessages(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchUserAgents(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp)
        ]);


        // Fetch EV Sessions and get the foundEvSessionId
        const evSessionResponse = await this.fetchEvSessions(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp);

        if (this.apiResponseEvSessions && this.apiResponseEvSessions.createdSessionId) {
          const foundEvSessionId = this.apiResponseEvSessions.createdSessionId;

          // If EV session fetch was successful, fetch related events and CDR
          await Promise.all([
            this.fetchEvSessionsEvents(foundEvSessionId, this.fromTimestamp, this.toTimestamp, orderUuidTrimmed),
            this.fetchEvSessionsCdr(foundEvSessionId, this.fromTimestamp, this.toTimestamp)
          ]);
        }else{
          this.isLoadingEvSessionsEvents = false;
          this.isLoadingEvSessionsCdr = false;
        }

      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // Handle the error appropriately
      }
    },
    validateInput() {
      // Simple validation: check if the input is not empty
      return this.orderUuid && this.orderUuid.trim().length > 23;
    },
    async loadPartnerNames() {
      try {
        this.partnerNames = await fetchPartnerNames(this.loggedInUserId);
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchApiGatewayHttpRequests(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingApiGatewayHttpRequests = true;
      this.apiResponseApiGatewayHttpRequests.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/api-gateway-http-requests`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        //console.log("httpResponseStatus11", httpResponseStatus);
        if (httpResponseStatus > 399) {
          this.apiResponseApiGatewayHttpRequests = {};
          this.apiResponseApiGatewayHttpRequests.errorMessage = "No HTTP Request was found.";
        } else {
          this.apiResponseApiGatewayHttpRequests = response.data;
        }

        if (this.apiResponseApiGatewayHttpRequests.messages && this.apiResponseApiGatewayHttpRequests.messages.length === 0) {
          this.apiResponseApiGatewayHttpRequests.errorMessage = "No HTTP Request was found.";
        }

      } catch (error) {
        this.apiResponseApiGatewayHttpRequests = {};
        this.apiResponseApiGatewayHttpRequests.errorMessage = error.response?.data?.error;
        console.error('Error fetching API Gateway HTTP Requests:', error);
      } finally {
        this.isLoadingApiGatewayHttpRequests = false;
      }
    },
    async fetchOrderStateEvents(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingApiOrderStateEvents = true;
      this.apiResponseApiOrderStateEvents = {};
      this.apiResponseApiOrderStateEvents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/order-status-events`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseApiOrderStateEvents = {};
          this.apiResponseApiOrderStateEvents.errorMessage = "No order event was found.";
        } else {
          this.apiResponseApiOrderStateEvents = response.data;
        }

        if (this.apiResponseApiOrderStateEvents.messages && this.apiResponseApiOrderStateEvents.messages.length === 0) {
          this.apiResponseApiOrderStateEvents.errorMessage = "No order event was found.";
        }
      } catch (error) {
        this.apiResponseApiOrderStateEvents = {};
        this.apiResponseApiOrderStateEvents.errorMessage = error.response?.data?.error;
        console.error('Error fetching order state events:', error);
      } finally {
        this.isLoadingApiOrderStateEvents = false
      }
    },
    async fetchPopEvents(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingPopEvents = true;
      this.apiResponsePopEvents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/pop-events`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponsePopEvents = {};
          this.apiResponsePopEvents.errorMessage = "No pop event was found.";
        } else {
          this.apiResponsePopEvents = response.data;
        }

        if (this.apiResponsePopEvents.messages && this.apiResponsePopEvents.messages.length === 0) {
          this.apiResponsePopEvents.errorMessage = "No pop event was found.";
        }
      } catch (error) {
        this.apiResponsePopEvents = {};
        this.apiResponsePopEvents.errorMessage = error.response?.data?.error;
        console.error('Error fetching pop events:', error);
      } finally {
        this.isLoadingPopEvents = false;
      }
    },
    async fetchErrorLogMessages(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingErrorLogMessages = true;
      this.apiResponseErrorLogMessages.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/error-log-messages`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseErrorLogMessages = {};
          this.apiResponseErrorLogMessages.errorMessage = "No log message with the warning or error level was found.";
        } else {
          this.apiResponseErrorLogMessages = response.data;
        }

        if (this.apiResponseErrorLogMessages.messages && this.apiResponseErrorLogMessages.messages.length === 0) {
          this.apiResponseErrorLogMessages.errorMessage = "No log message with the warning or error level was found.";
        }
      } catch (error) {
        this.apiResponseErrorLogMessages = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseErrorLogMessages.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingErrorLogMessages = false;
      }
    },
    async fetchUserAgents(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingUserAgents = true;
      this.apiResponseUserAgents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/user-agents`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderIdOrAccountId: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseUserAgents = {};
          this.apiResponseUserAgents.errorMessage = "No user agent for the entered order id was found..";
        } else {
          this.apiResponseUserAgents = response.data;
        }

        if (this.apiResponseUserAgents.messages && this.apiResponseUserAgents.totalNumberOfResults === 0) {
          this.apiResponseUserAgents.errorMessage = "No user agent for the entered order id was found.";
        }
      } catch (error) {
        this.apiResponseUserAgents = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseUserAgents.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingUserAgents = false;
      }
    },
    async fetchEvSessions(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingEvSessions = true;
      this.apiResponseEvSessions.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/pop-events/ev-sessions`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseEvSessions = {};
          this.apiResponseEvSessions.errorMessage = "No ev session has been created for the given order uuid.";
        } else {
          this.apiResponseEvSessions = response.data;
          this.foundEvSessionId = response.data.createdSessionId;
        }

        if (this.apiResponseEvSessions.messages && this.apiResponseEvSessions.totalNumberOfResults === 0) {
          this.apiResponseEvSessions.errorMessage = "No ev session has been created for the given order uuid.";
        }
      } catch (error) {
        this.apiResponseEvSessions = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseEvSessions.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingEvSessions = false;
      }
    },
    async fetchEvSessionsEvents(evSessionId, fromTimestamp, toTimestamp, orderUuid) {
      this.isLoadingEvSessionsEvents = true;
      this.apiResponseEvSessionsEvents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/pop-events/ev-sessions/events`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            evSessionId: evSessionId,
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseEvSessionsEvents = {};
          this.apiResponseEvSessionsEvents.errorMessage = "No session event has been found for the given ev session.";
        } else {
          this.apiResponseEvSessionsEvents = response.data;
        }

        if (this.apiResponseEvSessionsEvents.messages && this.apiResponseEvSessionsEvents.totalNumberOfResults === 0) {
          this.apiResponseEvSessionsEvents.errorMessage = "No session event has been found for the given ev session.";
        }
      } catch (error) {
        this.apiResponseEvSessionsEvents = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseEvSessionsEvents.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingEvSessionsEvents = false;
      }
    },
    async fetchEvSessionsCdr(evSessionId, fromTimestamp, toTimestamp) {
      this.isLoadingEvSessionsCdr = true;
      this.apiResponseEvSessionsCdr.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/pop-events/ev-sessions/cdr-records`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            evSessionId: evSessionId,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseEvSessionsCdr = {};
          this.apiResponseEvSessionsCdr.errorMessage = "No CDR record has been received for the given ev session.";
        } else {
          this.apiResponseEvSessionsCdr = response.data;
        }

        if (this.apiResponseEvSessionsCdr.messages && this.apiResponseEvSessionsCdr.totalNumberOfResults === 0) {
          this.apiResponseEvSessionsCdr.errorMessage = "No CDR record has been received for the given ev session.";
        }
      } catch (error) {
        this.apiResponseEvSessionsCdr = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseEvSessionsCdr.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingEvSessionsCdr = false;
      }
    },


    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sixMonths = 6 * 30 * 24 * 60 * 60 * 1000; // Approximately 6 months in milliseconds
      if (to - from > sixMonths) {
        this.showAlertTimestamps = true;
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    add1DayAndConvertDateToISOString(date) {
      if (date) {
        let dateInputValue = new Date(date);
        dateInputValue.setDate(dateInputValue.getDate() + 1); // Adding 1 day
        return dateInputValue.toISOString();
      }
    },
    initializeParameters() {
      const queryParams = this.$route.query;
      this.fromTimestamp = queryParams.from || this.getDefaultFromDate();
      this.toTimestamp = queryParams.to || this.getDefaultToDate();
      this.orderUuid = queryParams.orderUuid || '';

      // Explicitly check for selectedPartner in URL
      if (queryParams.selectedPartner) {
        console.log('Setting selectedPartner from URL:', queryParams.selectedPartner);
        this.selectedPartner = queryParams.selectedPartner;
      }
      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 90);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to && queryParams.orderUuid) {
        this.showShareThisSearch = true;
        this.updateTabs();
      }
    },
    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname;
      const queryParams = new URLSearchParams({
        from: this.fromTimestamp || '',
        to: this.toTimestamp || '',
        orderUuid: this.orderUuid?.trim() || '',
        selectedPartner: this.selectedPartner || ''
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('The Order UUID and Date Range have been successfully copied to the clipboard as a complete link.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },

    formatDateTime(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleString();
    },

    formatAddress(address) {
      if (!address) return null;
      const { street, houseNumber, city, countryCode, zip } = address;
      return [
        street + ' ' + houseNumber,
        zip,
        city
      ].filter(Boolean).join(', ');
    },

    hasValidLocation(location) {
      return location &&
          typeof location.latitude === 'number' &&
          typeof location.longitude === 'number';
    },

    getGoogleMapsLink(location) {
      if (!this.hasValidLocation(location)) return '#';
      return `https://www.google.com/maps?q=${location.latitude},${location.longitude}`;
    },

    selectOrderAndSearch(orderUuid) {
      this.orderUuid = orderUuid;
      this.updateTabs();
    },

    async fetchOrders() {
      this.stagingOrders = [];
      this.productionOrders = [];
      await Promise.all([
        this.fetchOrdersForEnvironment('production'),
        this.fetchOrdersForEnvironment('staging')
      ]);
    },

    async fetchOrdersForEnvironment(environment) {
      const loadingProp = environment === 'production' ? 'isLoadingProdOrders' : 'isLoadingStagingOrders';
      const ordersProp = environment === 'production' ? 'productionOrders' : 'stagingOrders';
      const errorProp = environment === 'production' ? 'productionOrdersError' : 'stagingOrdersError';

      this[loadingProp] = true;
      this[errorProp] = null;

      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/created-orders-by-partner`, {
          params: {
            nameOfDesiredPartner: this.selectedPartner,
            environment: environment
          },
          headers: {
            'uid': this.loggedInUserId,
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY
          }
        });

        if (!response.data || !response.data.data) {
          throw new Error('Invalid response format');
        }

        this[ordersProp] = response.data;
      } catch (error) {
        console.error(`Error fetching ${environment} orders:`, error);
        this[errorProp] = error.response?.data?.error ||
            'An error occurred while fetching orders. Please try again.';
        this[ordersProp] = { data: [] };
      } finally {
        this[loadingProp] = false;
      }
    }
  }
}
</script>

<style scoped>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

#order-details-search.ryd-search-input {
  border-radius: 0.375rem;;
}

#order-details-search-result-tabs .nav-item {
  border-right: 1px solid var(--theme-deafult);
}

#order-details-search-result-tabs .nav-item:last-child {
  border-right: none;
}


</style>
